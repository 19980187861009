import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {
	notificationChange: Subject<Object> = new Subject<Object>();

	constructor(private translateService: TranslateService) {}

	notify(severity: Severities, summary: string, detail: string) {
		this.notificationChange.next({ severity, summary, detail });
	}

	translateAndNotify(severity: Severities, summary: string, detail: string, interpolateParams?: Object) {
		let translatedDetail = this.translateService.instant(detail, interpolateParams);
		this.notificationChange.next({ severity, summary, translatedDetail });
	}
}
