import { Component } from '@angular/core';
import { DocumentBase } from '../document.base';

@Component({
	selector: 'mpp-hilfe',
	templateUrl: './hilfe.component.html'
})
export class HilfeComponent extends DocumentBase {
	TYPE = 'hilfe';
	title = 'Hilfe';
}
