import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { DocumentModule } from './modules/document/document.module';
import { IndexModule } from './modules/index/index.module';
import { HomePageComponent } from './core/components/home/home-page.component';
import { Roles } from './modules/auth/dto/Roles';
import { MeineDatenModule } from './modules/meine-daten/meine-daten.module';

const routes: Routes = [
	// INDEX MODULE
	{ path: '', loadChildren: () => IndexModule },

	{ path: '', loadChildren: () => DocumentModule },

	// HOME MODULE
	{ path: 'home', component: HomePageComponent, canActivate: [ AuthGuard ] },

	// BENUTZERKONTO MODULE
	{ path: 'meineDaten', loadChildren: () => MeineDatenModule, canActivate: [ AuthGuard ] },

	// VORGAENGE MODULE
	{
		path: 'vorgaenge',
		loadChildren: './modules/vorgaenge/vorgaenge.module#VorgaengeModule',
		canActivate: [ AuthGuard ],
		data: {
			expectedRole: Roles.Vorgaenge
		}
	},

	// DOKUMENTE MODULE
	{
		path: 'dokumente',
		loadChildren: './modules/dokumente/dokumente.module#DokumenteModule',
		canActivate: [ AuthGuard ],
		data: {
			expectedRole: Roles.Dokumente
		}
	},

	// PAGE NOT FOUND
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ],
	providers: []
})
export class AppRoutingModule {}
