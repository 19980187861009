import { Marktpartner } from './Marktpartner';

export class Adresse {
	id: number;
	marktpartner: Marktpartner;
	adresstyp: string;
	name: string;
	strasse: string;
	hausnummer: string;
	adresszusatz: string;
	plz: string;
	ort: string;
	stadtbezirk: string;
	bezirk: string;
	bundesland: string;
	land: string;
	telefon1: string;
	telefon2: string;
	telefax1: string;
	telefax2: string;
	email: string;
	internetadresse: string;

	static getLabel(adresse: Adresse): string {
		return ((adresse.name ? adresse.name + ', ' : '') +
			(adresse.land ? adresse.land + ', ' : '') +
			(adresse.plz ? adresse.plz + ', ' : '') +
			(adresse.ort ? adresse.ort + ', ' : '') +
			(adresse.strasse ? adresse.strasse + ', ' : '') +
			(adresse.hausnummer ? adresse.hausnummer + ', ' : '')).slice(0, -2);
	}

	static getLabelWithTyp(adresse: Adresse): string {
		return (adresse.adresstyp ? adresse.adresstyp + ', ' : '') + Adresse.getLabel(adresse);
	}
}
