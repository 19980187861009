import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { ChangeForgottenPasswordComponent } from './pages/change-forgotten-password/change-forgotten-password.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SharedModule } from '../shared/shared.module';
import { RegistrationService } from './services/registration.service';
import { CaptchaService } from './services/captcha.service';

@NgModule({
	imports: [ ButtonModule, CommonModule, FormsModule, AuthRoutingModule, SharedModule ],
	declarations: [ ChangeForgottenPasswordComponent, ChangePasswordComponent ],
	providers: [ RegistrationService, CaptchaService ],
	exports: [ ChangePasswordComponent ]
})
export class AuthModule {}
