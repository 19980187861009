import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LogService } from './log/log.service';
import * as jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'X-AUTH-TOKEN';
const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN';
const PROPERTIES_KEY = 'PROPERTIES';

const helper = new JwtHelperService();

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	constructor(private _logService: LogService) {}

	signOut() {
		this.removeTokens();
		window.sessionStorage.clear();
	}

	public saveToken(token: string) {
		this._logService.debug('TokenStorage saveToken() ', [ token ]);
		this.save(TOKEN_KEY, token);
	}

	public saveRefreshToken(token: string) {
		this._logService.debug('TokenStorage saveRefreshToken() ', [ token ]);
		this.save(REFRESH_TOKEN_KEY, token);
	}

	public saveProperties(properties: any) {
		this._logService.debug('TokenStorage savePropterties() ', [ properties ]);
		this.save(PROPERTIES_KEY, properties);
	}

	public getToken(): string {
		return this.get(TOKEN_KEY);
	}

	public getRefreshToken(): string {
		return this.get(REFRESH_TOKEN_KEY);
	}

	public getProperties(): any {
		return this.get(PROPERTIES_KEY);
	}

	public save(key: string, value: any) {
		this._logService.debug('TokenStorage save()');
		window.sessionStorage.removeItem(key);
		window.sessionStorage.setItem(key, value);
	}

	public get(key: string) {
		return sessionStorage.getItem(key);
	}

	public removeTokens() {
		window.sessionStorage.removeItem(TOKEN_KEY);
		window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
	}

	public getTokenExpirationDate(token: string): Date {
		const decoded = helper.decodeToken(token);

		if (decoded.exp === undefined) return null;

		const date = new Date(0);
		date.setUTCSeconds(decoded.exp);
		return date;
	}

	public isTokenExpired(token?: string): boolean {
		if (!token) token = this.getToken();
		if (!token) return true;

		const date = this.getTokenExpirationDate(token);
		if (date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}

	public getTokenPayload(): any {
		let token: string = this.getToken();
		let tokenPayload: any = null;
		if (token) {
			tokenPayload = jwt_decode(token);
		}
		return tokenPayload;
	}
}
