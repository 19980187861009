import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './pages/login/index.component';
import { ChangeForgottenPasswordComponent } from '../auth/pages/change-forgotten-password/change-forgotten-password.component';
import { SsoSuccessComponent } from './pages/sso-success/sso-success.component';

export const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'change-forgotten-password', component: ChangeForgottenPasswordComponent },
	{ path: 'login', component: IndexComponent },
	{ path: 'ssoSuccess', component: SsoSuccessComponent }
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ]
})
export class IndexRoutingModule {}
