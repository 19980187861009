import { AbstractControl } from '@angular/forms';

export class MppEmailValidation {
	static MatchEmail(AC: AbstractControl) {
		let email = AC.get('emailChangeNewEmail').value;
		let emailRepeat = AC.get('emailChangeNewEmailRepeat').value;

		if (email != emailRepeat) {
			AC.get('emailChangeNewEmailRepeat').setErrors({ MatchEmail: true });
		} else {
			return null;
		}
	}
}
