import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { JwtAuthenticationRequest } from '../../../../core/dto/JwtAuthenticationRequest';
import { AuthService } from '../../../../core/services/auth.service';
import { LoginResponse } from '../../../../core/dto/LoginResponse';
import { LoginSuccess } from '../../../../core/dto/LoginSuccess';
import { ActivatedRoute } from '@angular/router';
import { MppRoutes } from '../../../../core/common/MppRoutes';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

@Component({
	selector: 'mpp-login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
	@Output() onSuccess = new EventEmitter<LoginSuccess>();
	@Output() onFail = new EventEmitter<LoginSuccess>();

	returnUrl: string;
	loginIsPending: boolean = false;

	constructor(
		private _route: ActivatedRoute,
		private _authService: AuthService,
		private _localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || MppRoutes.HOME;
	}

	onSignin(form: NgForm) {
		this.loginIsPending = true;

		let authenticationRequest: JwtAuthenticationRequest = new JwtAuthenticationRequest(
			form.value.username,
			form.value.password
		);

		this._authService.signinUser(authenticationRequest).subscribe(
			(loginResponse: LoginResponse) => {
				this._localStorageService.save('IS-FIRST-LOGIN', loginResponse.firstLogin);
				this.loginIsPending = false;
				let loginSuccess = new LoginSuccess();
				loginSuccess.isFirstLogin = loginResponse.firstLogin;
				loginSuccess.returnUrl = this.returnUrl;
				loginSuccess.jwtToken = loginResponse.jwtToken;
				loginSuccess.refreshJwtToken = loginResponse.refreshToken;
				this.onSuccess.emit(loginSuccess);
			},
			() => {
				this.loginIsPending = false;
				this.onFail.emit();
			}
		);
	}
}
