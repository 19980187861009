import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationRequest } from '../../dto/RegistrationRequest';
import { HttpClient } from '@angular/common/http';
import { RegistrationResponse } from '../../dto/RegistrationResponse';
import { CaptchaResponse } from '../../dto/CaptchaResponse';
import { Captcha } from '../../dto/Captcha';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RegistrationService } from '../../services/registration.service';
import { CaptchaService } from '../../services/captcha.service';

/**
 *
 * EXAMPLE USAGE:
 *
 * <mpp-registration
 (onRegistrationSuccess)="registrationSuccess($event)"
 (onRegistrationFail)="registrationFail($event)">
 </mpp-registration>

 */
@Component({
	selector: 'mpp-registration',
	templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {
	@Output() onSuccess = new EventEmitter<void>();
	@Output() onFail = new EventEmitter<string[]>();
	@Output() onCancel = new EventEmitter<void>();

	registrationRequest: RegistrationRequest;
	captchaPath: SafeResourceUrl;
	captchaLoaded = false;

	errorMessages: string[] = null;
	registrationSuccess: boolean = false;

	constructor(
		private registrationService: RegistrationService,
		private captchaService: CaptchaService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.registrationRequest = new RegistrationRequest();
		this.getNewCaptcha();
	}

	onRegister(form: NgForm) {
		this.errorMessages = null;

		this.registrationRequest.email = form.value.email;
		this.registrationRequest.name = form.value.name;
		this.registrationRequest.telefon = form.value.telefon;
		this.registrationRequest.nachricht = form.value.nachricht;
		this.registrationRequest.captcha.content = form.value.captcha;

		this.registrationService
			.makeRegistration(this.registrationRequest)
			.subscribe((response: RegistrationResponse) => {
				if (response.valid) {
					this.registrationSuccess = true;
					this.onSuccess.emit();
				} else {
					this.onFail.emit(response.errorMessages);
				}
			});
	}

	private getNewCaptcha() {
		this.captchaLoaded = false;
		this.captchaService.getCaptcha().subscribe((response: CaptchaResponse) => {
			this.registrationRequest.captcha = new Captcha();
			this.registrationRequest.captcha.id = response.id;
			this.captchaPath = this.sanitizer.bypassSecurityTrustResourceUrl(
				'data:image/png;base64,' + response.captchaImage
			);
			this.captchaLoaded = true;
		});
	}

	onCancelHandler() {
		this.onCancel.emit();
	}
}
