import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'mpp-page-not-found',
  styleUrls: ['./page-not-found.component.scss'],
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
  isAuthenticated: boolean = false;

  constructor(private _authService: AuthService) {}

  ngOnInit() {}

  checkIsAuthenticated() {
    this.isAuthenticated = this._authService.isFullyAuthenticated();
    return this.isAuthenticated;
  }
}
