import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginSuccess } from '../../../../core/dto/LoginSuccess';
import { AuthService } from '../../../../core/services/auth.service';
import { timer } from 'rxjs';
import { LogService } from '../../../../core/services/log/log.service';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { BenutzerService } from 'src/app/core/services/benutzer.service';

@Component({
	selector: 'mpp-index',
	templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {
	_loginIsPending: boolean = false;

	showLogin: boolean;
	showChangePassword: boolean;
	showForgotPassword: boolean;
	showRegistrationRequest: boolean;
	showAllContent: boolean = false;

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _localStorageService: LocalStorageService,
		private _logService: LogService,
		private _notificationService: NotificationsService,
		private _translate: TranslateService,
		private benutzerService: BenutzerService
	) {}

	setViews(
		login?: boolean,
		changePassword?: boolean,
		forgotPassword?: boolean,
		registrationRequest?: boolean,
		showAllContent?: boolean
	) {
		this.showLogin = login;
		this.showChangePassword = changePassword;
		this.showForgotPassword = forgotPassword;
		this.showRegistrationRequest = registrationRequest;
		this.showAllContent = showAllContent;
	}

	ngOnInit() {
		this._authService.logout();
		this.setViews(true, false, false, false, true);
	}

	ngOnDestroy() {
		this._loginIsPending = false;
	}

	isAuthenticated() {
		return this._authService.isFullyAuthenticated();
	}

	onCancelHandler() {
		this._localStorageService.removeTokens();
		this.ngOnInit();
	}

	onLoginSuccessHandler(loginSuccessObject: LoginSuccess) {
		this._localStorageService.saveToken(loginSuccessObject.jwtToken);
		this._localStorageService.saveRefreshToken(loginSuccessObject.refreshJwtToken);
		this.benutzerService.getCurrentBenutzer().subscribe((benutzer) => {
			if (benutzer) {
				let welcome = 'Herzlich Willkommen';
				if (benutzer.anrede) {
					welcome = welcome + ' ' + benutzer.anrede;
				}
				if (benutzer.vorname) {
					welcome = welcome + ' ' + benutzer.vorname;
				}
				if (benutzer.name) {
					welcome = welcome + ' ' + benutzer.name;
				}
				if (benutzer.marktpartner && benutzer.marktpartner.name) {
					welcome = welcome + ' von ' + benutzer.marktpartner.name;
				}
				welcome = welcome + '!';
				this._notificationService.notify('success', 'Login', welcome);
			}
		});
		if (loginSuccessObject.isFirstLogin) {
			this.setViews(false, true, false, false, true);
		} else {
			this.setViews(false, false, false, false, false);
			timer(500).subscribe(() => {
				this._router.navigateByUrl(loginSuccessObject.returnUrl);
			});
		}
	}

	onLoginFailHandler() {
		this._loginIsPending = false;
		this._notificationService.notify('error', 'Login', 'Der Login ist fehlgeschlagen');
	}

	onChangePasswordSuccessHandler() {
		this._logService.log('IndexComponent onChangePasswordSuccess()');
		this._notificationService.notify('success', 'Passwort ändern', 'Das Passwort wurde erfolgreich geändert');
		this._localStorageService.removeTokens();
		this.ngOnInit();
	}
	onChangePasswordFailHandler() {
		this._notificationService.notify(
			'error',
			'Passwort ändern',
			'Fehler beim Ändern des Passwortes. Bitte versuchen sie es erneut.'
		);
	}

	onForgotPasswordClicked() {
		this.setViews(false, false, true, false, true);
	}

	onRegistrationRequestClicked() {
		this.setViews(false, false, false, true, true);
	}

	onRegistrationSuccessHandler() {
		this._logService.log('IndexComponent onRegistrationSuccessHandler()');
		timer(3000).subscribe(() => {
			this._localStorageService.removeTokens();
			this.ngOnInit();
		});
	}

	onRegistrationFailHandler(errorMessages: string[]) {
		let notifiableErrorMessage: string = '';
		errorMessages.forEach((e, index) => {
			notifiableErrorMessage += this._translate.instant(e);
			if (index < errorMessages.length - 1) {
				notifiableErrorMessage += '<br />';
			}
		});
		this._notificationService.notify('error', 'Registrierungsfehler', notifiableErrorMessage);
	}

	onForgotPasswordSuccessHandler() {
		this._logService.log('IndexComponent onForgotPasswordSuccessHandler()');
		timer(3000).subscribe(() => {
			this.setViews(true, false, false, false, true);
		});
	}

	onForgotPasswordFailHandler(errorMessage: string) {
		this._notificationService.translateAndNotify('error', 'Passwort vergessen', errorMessage);
	}
}
