import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LogEntry } from './log-entry';
import { LogLevel } from './log-level';

@Injectable()
export class LogService {
	level: LogLevel;
	logWithDate: boolean = false;
	isProduction: boolean = environment.production;

	debug(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Debug, optionalParams);
	}

	info(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Info, optionalParams);
	}

	warn(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Warn, optionalParams);
	}

	error(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Error, optionalParams);
	}

	fatal(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Fatal, optionalParams);
	}

	log(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.All, optionalParams);
	}

	private shouldLog(level: LogLevel): boolean {
		let ret: boolean = false;
		if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
			ret = true;
		}
		return ret;
	}

	private writeToLog(msg: string, level: LogLevel, params: any[]) {
		if (this.isProduction) {
			this.level = LogLevel.Off;
		} else {
			this.level = LogLevel.All;
		}
		if (this.shouldLog(level)) {
			let entry: LogEntry = new LogEntry();
			entry.message = msg;
			entry.level = level;
			entry.extraInfo = params;
			entry.logWithDate = this.logWithDate;
			if (entry.level === LogLevel.Error || entry.level === LogLevel.Fatal) {
				console.error(entry.buildLogString());
			} else {
				console.log(entry.buildLogString());
			}
		}
	}
}
