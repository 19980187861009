import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Benutzer } from 'src/app/core/dto/Benutzer';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import * as fromBenutzerReducers from '../../store/benutzer.reducer';
import { Marktpartner } from 'src/app/core/dto/Marktpartner';
import { map } from 'rxjs/operators';

@Component({
	selector: 'mpp-adressen-page',
	templateUrl: './adressen-page.component.html'
})
export class AdressenPageComponent {
	marktpartner$: Observable<Marktpartner[]>;

	constructor(private store: Store<AppState>) {
		this.marktpartner$ = this.store.pipe(
			select(fromBenutzerReducers.getBenutzer),
			map((benutzer: Benutzer) => {
				let marktpartner: Marktpartner[] = [];
				if (benutzer.marktpartner) {
					marktpartner.push(benutzer.marktpartner);
					if (benutzer.marktpartner.children && benutzer.marktpartner.children.length >= 1) {
						marktpartner.push(...benutzer.marktpartner.children);
					}
				}
				return marktpartner;
			})
		);
	}
}
