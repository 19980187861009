import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HtmlTextDocument } from '../../../core/dto/HtmlTextDocument';

@Injectable({
	providedIn: 'root'
})
export class HtmlTextService {
	readonly htmlTextUrl = 'api/docs/';

	constructor(private http: HttpClient) {}

	getHtmlTexts(type: string) {
		return this.http.get<HtmlTextDocument[]>(this.htmlTextUrl + type);
	}
}
