import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MppRoutingService {
	constructor(private router: Router) {}

	public goTo(to: string, seconds?: number) {
		if (seconds) {
			setTimeout(() => {
				this.router.navigate([ to ]);
			}, seconds * 1000);
		} else {
			this.router.navigate([ to ]);
		}
	}
}
