import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mpp-topbutton',
	templateUrl: './topbutton.component.html'
})
export class TopbuttonComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	goToTop() {
		document.getElementById('scroll-check').scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	showGoToTopButton() {
		if (document.getElementById('scroll-check').scrollHeight > document.getElementById('scroll-check').clientHeight)
			return true;
	}
}
