import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PasswordForgotRequest } from '../dto/PasswordForgotRequest';

@Injectable({
	providedIn: 'root'
})
export class ForgotPasswordService {
	constructor(private http: HttpClient) {}

	forgotPassword(passwordForgotRequest: PasswordForgotRequest): Observable<any> {
		return this.http.post(`api/password/forgot`, passwordForgotRequest, { responseType: 'text' });
	}
}
