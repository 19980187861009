import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
	selector: 'mpp-footer',
	templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
	isAuthenticated = false;
	version = require('./version.json');

	constructor(private _authService: AuthService) {}

	ngOnInit() {}

	checkIsAuthenticated() {
		this.isAuthenticated = this._authService.isFullyAuthenticated();
		return this.isAuthenticated;
	}
}
