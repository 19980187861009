export class ChangePasswordRequest {
	currentPassword: string;
	newPassword: string;
	newPasswordRepeat: string;

	constructor(currentPassword: string, newPassword: string, newPasswordRepeat: string) {
		this.currentPassword = currentPassword;
		this.newPassword = newPassword;
		this.newPasswordRepeat = newPasswordRepeat;
	}
}
