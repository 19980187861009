import { Adresse } from './Adresse';
import { MarktpartnerPerson } from './MarktpartnerPerson';
import { Benutzer } from './Benutzer';

export class Marktpartner {
	id: number;
	name: string;
	iknr: string;
	extId: number;
	parent?: Marktpartner;
	children?: Marktpartner[];
	adressen?: Adresse[];
	personen?: MarktpartnerPerson[];
	benutzer?: Benutzer;

	static getHauptadresse(marktpartner: Marktpartner): Adresse {
		if (marktpartner.adressen && marktpartner.adressen.length > 0) {
			return marktpartner.adressen.find((a) => a.adresstyp === 'Hauptadresse');
		} else {
			return null;
		}
	}

	static getLabel(marktpartner: Marktpartner): string {
		let adresse: Adresse = Marktpartner.getHauptadresse(marktpartner);
		if (adresse) {
			return Adresse.getLabel(adresse);
		} else {
			return this.name;
		}
	}
}
