import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonModule } from 'primeng/button';
import {
	CalendarModule,
	InputTextModule,
	PanelMenuModule,
	ProgressBarModule,
	SelectButtonModule
} from 'primeng/primeng';
import { MenuModule } from 'primeng/menu';
import { TopbuttonComponent } from './components/topbutton/topbutton.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { DataViewModule } from 'primeng/dataview';
import { DateFormatPipe } from './pipes/DateFormatPipe';

@NgModule({
	imports: [
		CommonModule,
		SharedRoutingModule,
		ButtonModule,
		InputTextModule,
		MenuModule,
		ProgressBarModule,
		TableModule,
		PaginatorModule,
		SelectButtonModule,
		CalendarModule,
		PanelMenuModule,
		DataViewModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ HttpClient ]
			}
		})
	],
	declarations: [ HeaderComponent, FooterComponent, TopbuttonComponent, DateFormatPipe ],
	exports: [
		ButtonModule,
		InputTextModule,
		MenuModule,
		ProgressBarModule,
		TranslateModule,
		TableModule,
		PaginatorModule,
		CalendarModule,
		SelectButtonModule,
		PanelMenuModule,
		DataViewModule,
		HeaderComponent,
		FooterComponent,
		TopbuttonComponent
	]
})
export class SharedModule {
	constructor(private _translate: TranslateService) {
		this._translate.setDefaultLang('de');
	}
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/mpp/assets/i18n/');
}
