import { Component, OnInit, Input } from '@angular/core';
import { Marktpartner } from 'src/app/core/dto/Marktpartner';
import { Adresse } from 'src/app/core/dto/Adresse';

@Component({
	selector: 'mpp-adressen',
	templateUrl: './adressen.component.html'
})
export class AdressenComponent implements OnInit {
	@Input()
	set _marktpartner(_marktpartner: Marktpartner[]) {
		this.marktpartner = _marktpartner;
		if (_marktpartner && _marktpartner.length >= 1) {
			this.selectedMarktpartner = _marktpartner[0];
			this.adressen = this.selectedMarktpartner.adressen;
			this.selectedAdresse = Marktpartner.getHauptadresse(this.selectedMarktpartner);
		}
	}
	marktpartner: Marktpartner[];
	selectedMarktpartner: Marktpartner;
	suggestedMarktpartner: Marktpartner[];
	adressen: Adresse[];
	selectedAdresse: Adresse;
	suggestedAdressen: Adresse[];

	persCols: any[];
	persRows: number = 10;
	persSortField: string = 'name';
	persSortOrder: number = 1;

	benutzerCols: any[];
	benutzerRows: number = 10;
	benutzerField: string = 'benutzerName';
	benutzerOrder: number = 1;

	ngOnInit(): void {
		this.persCols = [
			{ field: 'anrede', header: 'Anrede' },
			{ field: 'vorname', header: 'Vorname' },
			{ field: 'name', header: 'Name' },
			{ field: 'position', header: 'Position' }
		];
		this.benutzerCols = [
			{ field: 'benutzerName', header: 'Login' },
			{ field: 'anrede', header: 'Anrede' },
			{ field: 'vorname', header: 'Vorname' },
			{ field: 'name', header: 'Name' }
		];
	}

	searchAdressen(event): void {
		if (event.query.length >= 1) {
			this.suggestedAdressen = this.adressen.filter((a: Adresse) =>
				Adresse.getLabelWithTyp(a).toLowerCase().includes(event.query.toLowerCase())
			);
		} else {
			this.suggestedAdressen = [ ...this.adressen ];
		}
	}

	searchMarktpartner(event): void {
		if (event.query.length >= 1) {
			this.suggestedMarktpartner = this.marktpartner.filter((m: Marktpartner) =>
				Marktpartner.getLabel(m).toLowerCase().includes(event.query.toLowerCase())
			);
		} else {
			this.suggestedMarktpartner = [ ...this.marktpartner ];
		}
	}

	selectMarktpartner() {
		this.adressen = this.selectedMarktpartner.adressen;
		this.selectedAdresse = Marktpartner.getHauptadresse(this.selectedMarktpartner);
	}

	adresseConversionMethod(adresse: Adresse): string {
		return Adresse.getLabelWithTyp(adresse);
	}

	marktpartnerConversionMethod(marktpartner: Marktpartner): string {
		return Marktpartner.getLabel(marktpartner);
	}
}
