import { PasswordChangeService } from '../../../../core/services/password-change.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MppRoutes } from '../../../../core/common/MppRoutes';
import { MppRoutingService } from '../../../../core/services/mpp-routing-service';
import { ChangeForgottenPasswordRequest } from '../../../../core/dto/ChangeForgottenPasswordRequest';
import { PasswordValidationResponse } from '../../../../core/dto/PasswordValidationResponse';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { AuthService } from '../../../../core/services/auth.service';
import { NotificationsService } from '../../../../core/services/notifications.service';

@Component({
	selector: 'mpp-change-forgotten-password',
	templateUrl: './change-forgotten-password.component.html'
})
export class ChangeForgottenPasswordComponent implements OnInit {
	PARAM_FORGOT_PASSWORD_TOKEN = 'token';

	tokenIsValid: boolean;
	passwordCriteriaMessages: string[];

	passwordChangeErrors: any;
	passwordIsChanged: boolean;

	passwordChangeIsPending: boolean = false;
	passwordConstraintsIsPending: boolean = false;

	constructor(
		private _route: ActivatedRoute,
		private _passwordChangeService: PasswordChangeService,
		private _router: Router,
		private _mppRoutingService: MppRoutingService,
		private _localStorageService: LocalStorageService,
		private _authService: AuthService,
		private _notificationService: NotificationsService
	) {}

	ngOnInit() {
		this.passwordConstraintsIsPending = true;

		this.readAndSetToken();
		this._authService.setUserIsChangingPassword();
		this._passwordChangeService.checkChangePassword().subscribe(
			(checkChangePasswordResponse) => {
				this.passwordConstraintsIsPending = false;

				if (checkChangePasswordResponse.validToken) {
					this.tokenIsValid = true;
					this.getPasswordConstraintsAndCreatePasswordCriteriaMessages();
				} else {
					this.tokenIsValid = false;
					this._localStorageService.removeTokens();
					this._mppRoutingService.goTo(MppRoutes.LOGIN, 3);
				}
			},
			(error) => {
				this._localStorageService.removeTokens();
				this._mppRoutingService.goTo(MppRoutes.LOGIN);
				this._notificationService.notify(
					'error',
					'Ungültiger Token',
					'Ihr Token ist abgelaufen. Falls Sie ihr Passwort ändern möchten, so stellen sie eine erneute Anfrage PASSWORT VERGESSEN.'
				);
			}
		);
	}

	ngOnDestroy() {
		this._authService.unsetUserIsChangingPassword();
	}

	onPasswordChange(form: NgForm) {
		this.passwordChangeIsPending = true;
		const changeForgottenPasswordRequest = new ChangeForgottenPasswordRequest(
			form.value.newPassword,
			form.value.newPasswordRepeat
		);

		this._passwordChangeService
			.changeForgottenPassword(changeForgottenPasswordRequest)
			.subscribe(
				(passwordValidationResponse) => this.changePasswordResponseHandler(passwordValidationResponse),
				(error) => this.changePasswordResponseErrorHandler(error)
			);
	}

	onCancelClicked() {
		this._localStorageService.removeTokens();
		this._mppRoutingService.goTo(MppRoutes.LOGIN);
	}

	private getPasswordConstraintsAndCreatePasswordCriteriaMessages() {
		this.getPasswordConstraints().subscribe((passwordConstraints) => {
			this.passwordCriteriaMessages = this._passwordChangeService.createPasswordCriteriaMessages(
				passwordConstraints
			);
		});
	}

	private changePasswordResponseHandler(passwordValidationResponse: PasswordValidationResponse) {
		this.passwordChangeIsPending = false;
		this.passwordIsChanged = passwordValidationResponse.valid;

		if (!this.passwordIsChanged) {
			this.passwordChangeErrors = passwordValidationResponse.errors;
		} else {
			this._localStorageService.removeTokens();
			this._mppRoutingService.goTo(MppRoutes.LOGIN, 3);
		}
	}

	private changePasswordResponseErrorHandler(error: any) {
		this.passwordChangeIsPending = false;
		this.passwordChangeErrors = [ 'Ihr Token ist ungültig!' ];
		console.debug(error);
	}

	private readAndSetToken() {
		return this._route.queryParams.subscribe((params) => {
			let token = params && params[this.PARAM_FORGOT_PASSWORD_TOKEN];
			this._localStorageService.saveToken(token);
		});
	}

	private getPasswordConstraints() {
		return this._passwordChangeService.getPasswordConstraints();
	}
}
