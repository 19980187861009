import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../../../core/services/notifications.service';

@Component({
	selector: 'mpp-notifications',
	templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnDestroy {
	msgs: Message[] = [];
	lifeTime = 3000;
	isSticky = false;
	subscription: Subscription;

	constructor(private notificationsService: NotificationsService) {}

	ngOnInit() {
		this.subscribeToNotifications();
	}

	subscribeToNotifications() {
		this.subscription = this.notificationsService.notificationChange.subscribe((notification) => {
			this.msgs.length = 0;
			this.msgs = [];
			this.msgs.push(notification);
		});
	}

	ngOnDestroy() {
		this.subscription && this.subscription.unsubscribe();
	}
}
