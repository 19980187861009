import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ForgotPasswordService } from '../../../../core/services/forgot-password.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CaptchaResponse } from 'src/app/modules/auth/dto/CaptchaResponse';
import { Captcha } from 'src/app/modules/auth/dto/Captcha';
import { PasswordForgotRequest } from 'src/app/core/dto/PasswordForgotRequest';
import { catchError } from 'rxjs/operators';
import { of, throwError, Observable } from 'rxjs';
import { CaptchaService } from '../../services/captcha.service';

@Component({
	selector: 'mpp-forgot-password',
	templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
	@Output() onSuccess = new EventEmitter<void>();
	@Output() onCancel = new EventEmitter<void>();
	@Output() onFail = new EventEmitter<string>();

	passwortForgotRequest: PasswordForgotRequest;
	showSuccessMessage: boolean = false;

	captchaPath: SafeResourceUrl;
	captchaLoaded = false;

	constructor(
		private forgotPasswordService: ForgotPasswordService,
		private captchaService: CaptchaService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.passwortForgotRequest = new PasswordForgotRequest();
		this.getNewCaptcha();
	}

	onForgot(form: NgForm) {
		this.passwortForgotRequest.username = form.value.forgotUsername;
		this.passwortForgotRequest.captcha.content = form.value.captcha;
		this.forgotPasswordService
			.forgotPassword(this.passwortForgotRequest)
			.pipe(
				catchError((res) => {
					return throwError(res.error);
				})
			)
			.subscribe(
				() => {
					this.showSuccessMessage = true;
					this.onSuccess.emit();
				},
				(error) => {
					this.onFail.emit(error);
				}
			);
	}

	onCancelClicked() {
		this.onCancel.emit();
	}

	getNewCaptcha() {
		this.captchaLoaded = false;
		this.captchaService.getCaptcha().subscribe((response: CaptchaResponse) => {
			this.passwortForgotRequest.captcha = new Captcha();
			this.passwortForgotRequest.captcha.id = response.id;
			this.captchaPath = this.sanitizer.bypassSecurityTrustResourceUrl(
				'data:image/png;base64,' + response.captchaImage
			);
			this.captchaLoaded = true;
		});
	}
}
