import { Component } from '@angular/core';
import { DocumentBase } from '../document.base';

@Component({
	selector: 'mpp-datenschutz',
	templateUrl: './datenschutz.component.html'
})
export class DatenschutzComponent extends DocumentBase {
	TYPE = 'datenschutz';
	title = 'Datenschutz';
}
