import { OnInit, Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Tile } from '../../dto/Tile';
import { BenutzerService } from 'src/app/core/services/benutzer.service';
import { RoleService } from '../../services/role.service';
import { Roles } from 'src/app/modules/auth/dto/Roles';
import { VorgangTypKategorie } from 'src/app/modules/vorgaenge/dto/VorgangTypKategorie';
import { Vorgangstyp } from 'src/app/modules/vorgaenge/dto/VorgangTyp';
import { HeaderTitleService } from '../../services/header-title.service';

@Component({
	selector: 'mpp-home-page',
	templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {
	constructor(
		private benutzerService: BenutzerService,
		private roleService: RoleService,
		private headerTitleService: HeaderTitleService
	) {}

	tiles$: Observable<Tile[]>;

	ngOnInit(): void {
		this.headerTitleService.setTitle('');
		this.preparePage();
	}

	private preparePage() {
		this.tiles$ = of([]);
		this.benutzerService.getCurrentBenutzer().subscribe((benutzer) => {
			let tiles: Tile[] = [];
			let nachrichtVorgangstyp: Vorgangstyp = benutzer.vorgangstypen.find(
				(e) => e.kategorie === VorgangTypKategorie.NACHRICHT
			);
			let nachrichtenVorgangstypen: boolean = benutzer.vorgangstypen.some((e) => e.thema === 'Nachrichten');

			tiles.push({ title: 'Meine Daten', link: '/meineDaten' });

			if (this.roleService.hasRole(Roles.Vorgaenge)) {
				tiles.push({ title: 'Vorgänge', link: '/vorgaenge' });
			}
			if (this.roleService.hasRole(Roles.Dokumente)) {
				tiles.push({ title: 'Dokumente', link: '/dokumente' });
			}
			if (this.roleService.hasRole(Roles.Vorgaenge) && nachrichtenVorgangstypen) {
				tiles.push({ title: 'Nachrichten', link: '/vorgaenge' });
			}
			if (this.roleService.hasRole(Roles.Vorgaenge) && nachrichtVorgangstyp) {
				tiles.push({
					title: 'Nachricht an Kasse',
					link: '/vorgaenge/neu/' + nachrichtVorgangstyp.gruppierungsmerkmal
				});
			}
			this.tiles$ = of(tiles);
		});
	}
}
