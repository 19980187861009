import * as BenutzerActions from './benutzer.actions';
import { BenutzerState } from './benutzer.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Benutzer, emptyBenutzer } from '../../../core/dto/Benutzer';

export function benutzerReducer(state: Benutzer = emptyBenutzer, action: BenutzerActions.Actions): Benutzer {
	switch (action.type) {
		case BenutzerActions.GET_BENUTZER:
			return state;
		case BenutzerActions.GET_BENUTZER_SUCCESS:
			return action.payload;
		default:
			return state;
	}
}

export function errorsReducer(state: string[] = [], action: BenutzerActions.Actions): string[] {
	if (action.type === BenutzerActions.SET_ERRORS) {
		return action.payload;
	} else {
		return state;
	}
}

const getBenutzerState = createFeatureSelector<BenutzerState>('benutzerFeature');

export const getBenutzer = createSelector(getBenutzerState, (state: BenutzerState) => state.benutzer);

export const getErrors = createSelector(getBenutzerState, (state: BenutzerState) => state.errors);
