import { Vorgangstyp } from "src/app/modules/vorgaenge/dto/VorgangTyp";
import { Marktpartner } from "./Marktpartner";

export class Benutzer {
  benutzerName: string;
  anrede: string;
  vorname: string;
  name: string;
  titel: string;
  email: string;
  telefon: string;
  telefax: string;
  berechtigungsgruppen: string[];
  marktpartner: Marktpartner;
  angemeldetSeit: string;
  vorgangstypen: Vorgangstyp[];
}

export const emptyBenutzer: Benutzer = {
	benutzerName: '',
	anrede: '',
	angemeldetSeit: '',
	email: '',
	vorname: '',
	berechtigungsgruppen: [],
	marktpartner: null,
	name: '',
	titel: '',
	telefax: '',
	telefon: '',
	vorgangstypen: []
};
