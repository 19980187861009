import { Component, OnInit, Input } from '@angular/core';
import { Tile } from '../../dto/Tile';

@Component({
	selector: 'mpp-home',
	templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
	@Input() tiles: Tile[];
	@Input() welcome: string;

	ngOnInit(): void {
		const body = document.getElementsByTagName('body')[0];
		body.classList.add('page-background');
	}

	ngOnDestroy(): void {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('page-background');
	}
}
