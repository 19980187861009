import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Benutzer } from '../../../../core/dto/Benutzer';
import { BenutzerService } from '../../../../core/services/benutzer.service';
import { NgForm } from '@angular/forms';
import { ChangeEmailRequest } from '../../../../core/dto/ChangeEmailRequest';
import { ChangeEmailResponse } from '../../../../core/dto/ChangeEmailResponse';
import { LogService } from '../../../../core/services/log/log.service';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { AppState } from '../../../../app.state';
import { select, Store } from '@ngrx/store';
import * as fromBenutzerActions from '../../store/benutzer.actions';
import * as fromBenutzerReducers from '../../store/benutzer.reducer';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'mpp-benutzerkonto-page',
	templateUrl: './benutzerkonto.page.html'
})
export class BenutzerkontoPage {
	benutzer$: Observable<Benutzer>;
	changeEmailErrors$: Observable<string[]>;

	constructor(
		private _benutzerService: BenutzerService,
		private _logService: LogService,
		private _notificationService: NotificationsService,
		private store: Store<AppState>,
		private translateservice: TranslateService
	) {
		this.benutzer$ = this.store.pipe(select(fromBenutzerReducers.getBenutzer));
		this.changeEmailErrors$ = this.store.pipe(select(fromBenutzerReducers.getErrors));
	}

	saveEmail(benutzerForm: NgForm) {
		this._logService.debug('change email request');

		let changeEmailRequest: ChangeEmailRequest = new ChangeEmailRequest();
		changeEmailRequest.newEmail = benutzerForm.value.emailChangeNewEmail;
		changeEmailRequest.newEmailRepeat = benutzerForm.value.emailChangeNewEmailRepeat;

		this._logService.debug(JSON.stringify(changeEmailRequest));

		this._benutzerService.saveEmail(changeEmailRequest).subscribe((response: ChangeEmailResponse) => {
			let errors: string[] = [];
			if (!response.valid) {
				for (let key of response.errorMessages) {
					errors.push(this.translateservice.instant(key));
				}
			} else {
				this._notificationService.translateAndNotify('success', 'Email', 'email.change.success');
				this.store.dispatch(new fromBenutzerActions.GetBenutzerSuccess(response.updatedBenutzer));
			}
			this.store.dispatch(new fromBenutzerActions.SetErrors(errors));
		});
	}
}
