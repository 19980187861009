import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckChangePasswordResponse } from '../dto/CheckChangePasswordResponse';
import { PasswordConstraints } from '../dto/PasswordConstraints';
import { ChangeForgottenPasswordRequest } from '../dto/ChangeForgottenPasswordRequest';
import { ChangePasswordRequest } from '../dto/ChangePasswordRequest';
import { TranslateService } from '@ngx-translate/core';
import { PasswordValidationResponse } from '../dto/PasswordValidationResponse';

@Injectable({
	providedIn: 'root'
})
export class PasswordChangeService {
	constructor(private _http: HttpClient, private _translate: TranslateService) {}

	changeForgottenPassword(request: ChangeForgottenPasswordRequest): Observable<PasswordValidationResponse> {
		const CHANGE_PASSWORD_URI = 'api/password/change-forgotten-password';
		return this._http.post<PasswordValidationResponse>(CHANGE_PASSWORD_URI, request);
	}

	changePassword(request: ChangePasswordRequest): Observable<PasswordValidationResponse> {
		const CHANGE_PASSWORD_URI = 'api/password/change-password';
		return this._http.post<PasswordValidationResponse>(CHANGE_PASSWORD_URI, request);
	}

	checkChangePassword() {
		const CHECK_CHANGE_PASSWORD_URI = 'api/password/check';
		return this._http.get<CheckChangePasswordResponse>(CHECK_CHANGE_PASSWORD_URI);
	}

	getPasswordConstraints(): Observable<PasswordConstraints> {
		const GET_PASSWORD_CONSTRAINTS_URI = 'api/password/get-password-constraints';
		return this._http.get<PasswordConstraints>(GET_PASSWORD_CONSTRAINTS_URI);
	}

	createPasswordCriteriaMessages(passwordConstraints) {
		let result = [];

		// TODO-oezkan: use application wide text properties here.
		if (passwordConstraints.min) {
			result.push(
				this.translateCriteriaMessage('validation.password.constraints.min.allowed', passwordConstraints.min)
			);
		}
		if (passwordConstraints.max) {
			result.push(
				this.translateCriteriaMessage('validation.password.constraints.max.allowed', passwordConstraints.max)
			);
		}

		if (passwordConstraints.zahl) {
			result.push(this.translateCriteriaMessage('validation.password.constraints.zahl.allowed'));
		} else {
			result.push(this.translateCriteriaMessage('validation.password.constraints.zahl.not-allowed'));
		}

		if (passwordConstraints.kleinbuchstabe) {
			result.push(this.translateCriteriaMessage('validation.password.constraints.kleinbuchstabe.allowed'));
		} else {
			result.push(this.translateCriteriaMessage('validation.password.constraints.kleinbuchstabe.not-allowed'));
		}

		if (passwordConstraints.grossbuchstabe) {
			result.push(this.translateCriteriaMessage('validation.password.constraints.grossbuchstabe.allowed'));
		} else {
			result.push(this.translateCriteriaMessage('validation.password.constraints.grossbuchstabe.not-allowed'));
		}

		if (passwordConstraints.sonderzeichen) {
			result.push(this.translateCriteriaMessage('validation.password.constraints.sonderzeichen.allowed'));
		} else {
			result.push(this.translateCriteriaMessage('validation.password.constraints.sonderzeichen.not-allowed'));
		}

		if (passwordConstraints.sonderzeichenAusschluss) {
			result.push(
				this.translateCriteriaMessage(
					'validation.password.constraints.sonderzeichenausschluss.allowed',
					passwordConstraints.sonderzeichenAusschluss
				)
			);
		}

		return result;
	}

	private translateCriteriaMessage(key: string, value?: any) {
		return this._translate.instant(key, { value: value });
	}
}
