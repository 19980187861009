import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LogService } from './log/log.service';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

const TOKEN_HEADER_KEY = 'X-AUTH-TOKEN';

@Injectable()
export class Interceptor implements HttpInterceptor {
	constructor(private authService: AuthService, private router: Router, private logService: LogService) {}

	inflightAuthRequest = null;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// urls with no authentification - passtrough
		let noCheckUrls: string[] = [
			'api/login',
			'mpp/api/login',
			'api/refreshToken',
			'/mpp/api/refreshToken',
			'api/captcha/get',
			'/mpp/api/captcha/get',
			'api/password/forgot',
			'/mpp/api/password/forgot',
			'api/registration/request',
			'/mpp/api/registration/request',
			'assets/i18n/de.json',
			'/mpp/assets/i18n/de.json',
			'api/docs/impressum',
			'/mpp/api/docs/impressum',
			'api/docs/faq',
			'/mpp/api/docs/faq',
			'api/docs/datenschutz',
			'/mpp/api/docs/datenschutz',
			'api/config/angular',
			'/mpp/api/config/angular'
		];
		if (noCheckUrls.includes(request.url)) {
			return next.handle(request).pipe(this.tapWithLog());
		}

		// get token if not valid use
		this.inflightAuthRequest = this.authService.getToken();

		return this.inflightAuthRequest.pipe(
			switchMap((newToken: string) => {
				// unset request inflight
				this.inflightAuthRequest = null;

				// use the newly returned token
				const authReq = request.clone({
					headers: request.headers.set(TOKEN_HEADER_KEY, newToken ? newToken : '')
				});
				return next.handle(authReq).pipe(this.tapWithLog());
			}),
			catchError((error) => {
				if (error.status === 401) {
					if (!this.inflightAuthRequest) {
						this.inflightAuthRequest = this.authService.refreshToken();

						if (!this.inflightAuthRequest) {
							// remove existing tokens
							localStorage.clear();
							this.router.navigate([ '/login' ]);
							return throwError(error);
						}
					}

					return this.inflightAuthRequest.pipe(
						switchMap((newToken: string) => {
							// unset inflight request
							this.inflightAuthRequest = null;

							// clone the original request
							const authReqRepeat = request.clone({
								headers: request.headers.set(TOKEN_HEADER_KEY, newToken ? newToken : '')
							});

							// resend the request
							return next.handle(authReqRepeat).pipe(this.tapWithLog());
						})
					);
				} else {
					// on unexpected error by authentification - logout
					localStorage.clear();
					this.router.navigate([ '/login' ]);
					return throwError(error);
				}
			})
		);
	}

	private tapWithLog() {
		return tap(
			(event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.logService.debug('Interceptor: event instanceof HttpResponse', [ event ]);
				}
			},
			(err: any) => {
				if (err instanceof HttpErrorResponse) {
					this.logService.error('Interceptor: err instanceof HttpErrorResponse', [ err ]);
					if (err.status === 401) {
						this.logService.error('Interceptor: response error', [ err.status ]);
					} else if (err.status === 404) {
						this.logService.error('Interceptor: response error', [ err.status ]);
					} else if (err.status === 405) {
						this.logService.error('Interceptor: response error', [ err.status ]);
					} else if (err.status === 504) {
						this.logService.error('Interceptor: response error', [ err.status ]);
					}
				}
			}
		);
	}
}
