import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeineDatenRoutingModule } from './meine-daten-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, TabViewModule, TreeModule, AutoCompleteModule, FieldsetModule } from 'primeng/primeng';
import { AuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { BenutzerService } from '../../core/services/benutzer.service';
import { StoreModule } from '@ngrx/store';
import { benutzerReducer, errorsReducer } from './store/benutzer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BenutzerEffects } from './store/benutzer.effects';
import { BenutzerkontoComponent } from './components/benutzerkonto/benutzerkonto.component';
import { BenutzerkontoPage } from './components/benutzerkonto/benutzerkonto.page';
import { MeineDatenComponent } from './components/meine-daten/meine-daten.component';
import { AdressenPageComponent } from './components/adressen/adressen-page.component';
import { AdressenComponent } from './components/adressen/adressen.component';

@NgModule({
	imports: [
		CommonModule,
		MeineDatenRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		ButtonModule,
		AuthModule,
		SharedModule,
		TabViewModule,
		TreeModule,
		StoreModule.forFeature('benutzerFeature', {
			benutzer: benutzerReducer,
			errors: errorsReducer
		}),
		EffectsModule.forFeature([ BenutzerEffects ]),
		AutoCompleteModule,
		FieldsetModule
	],
	providers: [ BenutzerService ],
	declarations: [
		MeineDatenComponent,
		BenutzerkontoPage,
		BenutzerkontoComponent,
		AdressenPageComponent,
		AdressenComponent
	],
	exports: [ FormsModule, ReactiveFormsModule ]
})
export class MeineDatenModule {}
