import { Action } from '@ngrx/store';
import { Benutzer } from '../../../core/dto/Benutzer';

export const GET_BENUTZER = 'GET_BENUTZER';
export const GET_BENUTZER_SUCCESS = 'GET_BENUTZER_SUCCESS';
export const SET_ERRORS = 'SET_ERRORS';

export class GetBenutzer implements Action {
	readonly type = GET_BENUTZER;

	constructor() {}
}

export class GetBenutzerSuccess implements Action {
	readonly type = GET_BENUTZER_SUCCESS;

	constructor(public payload: Benutzer) {}
}

export class SetErrors implements Action {
	readonly type = SET_ERRORS;

	constructor(public payload: string[]) {}
}

export type Actions = GetBenutzer | GetBenutzerSuccess | SetErrors;
