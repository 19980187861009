import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { MppRoutingService } from '../../../../core/services/mpp-routing-service';
import { MppRoutes } from '../../../../core/common/MppRoutes';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { RoleService } from 'src/app/core/services/role.service';
import { Roles } from 'src/app/modules/auth/dto/Roles';
import { BenutzerService } from 'src/app/core/services/benutzer.service';
import { Observable, of } from 'rxjs';
import { HeaderTitleService } from 'src/app/core/services/header-title.service';

@Component({
	selector: 'mpp-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
	component = this;
	Roles = Roles;
	name$: Observable<string>;
	marktpartner$: Observable<string>;
	title: string;

	constructor(
		private _authService: AuthService,
		private _routingService: MppRoutingService,
		private _notificationService: NotificationsService,
		private headerTitleService: HeaderTitleService,
		public roleService: RoleService,
		public benutzerService: BenutzerService
	) {}

	ngOnInit() {
		this.headerTitleService.title.subscribe((updatedTitle) => {
			this.title = updatedTitle;
		});
		this.publishUserData();
	}

	publishUserData() {
		this.benutzerService.getCurrentBenutzer().subscribe((benutzer) => {
			if (benutzer) {
				this.name$ = of(benutzer.vorname + ' ' + benutzer.name);
				if (benutzer.marktpartner) {
					let marktpartner: string = '';
					let maxLength = 20;
					if (benutzer.marktpartner.name.length <= maxLength) {
						marktpartner = benutzer.marktpartner.name;
					} else {
						marktpartner = benutzer.marktpartner.name.substring(0, maxLength) + '...';
					}
					this.marktpartner$ = of(marktpartner);
				}
			}
		});
	}

	logout() {
		this._authService.logout();
		this._routingService.goTo(MppRoutes.LOGIN);
		this._notificationService.notify('success', 'Abgemeldet', 'Sie haben sich erfolgreich abgemeldet');
	}

	isAuthenticated() {
		return this._authService.isFullyAuthenticated();
	}
}
