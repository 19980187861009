import { interval as observableInterval, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { MppRoutingService } from './core/services/mpp-routing-service';
import { NotificationsService } from './core/services/notifications.service';
import { MppRoutes } from './core/common/MppRoutes';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	isAuthenticated = false;
	pageContentElement: any;
	showScrollButton = false;

	ONE_MINUTE: number = 60 * 1000;

	checkUserAuthenticationSubscription: Subscription;

	constructor(
		private _authService: AuthService,
		private _localStorageService: LocalStorageService,
		private _mppRoutingService: MppRoutingService,
		private _notificationService: NotificationsService
	) {}

	ngOnInit() {
		this.subscribeToAuthenticationCheck();
	}

	initBackground() {
		return !this.checkIsAuthenticated();
	}

	checkIsAuthenticated() {
		this.isAuthenticated = this._authService.isFullyAuthenticated();
		return this.isAuthenticated;
	}

	onScroll(event: any) {
		if (!this.pageContentElement) {
			this.pageContentElement = event.srcElement;
		}

		this.showScrollButton = this.pageContentElement.scrollTop > 20;
	}

	scrollToTop() {
		if (this.pageContentElement) {
			this.pageContentElement.scrollTop = 0;
		}
	}

	subscribeToAuthenticationCheck() {
		this.checkUserAuthenticationSubscription = observableInterval(this.ONE_MINUTE).subscribe((next) => {
			if (this._authService.isAuthenticated()) {
				this.notifyTimesLeft();
			} else {
				if (this.tokenIsStored()) {
					this._localStorageService.removeTokens();
					this._notificationService.notify(
						'error',
						'Sitzung beendet',
						'Ihre Sitzung ist beendet. Sie wurden automatisch aus dem System ausgeloggt.'
					);
					this._mppRoutingService.goTo(MppRoutes.LOGIN);
				}
			}
		});
	}

	private tokenIsStored() {
		return this._localStorageService.getToken() && this._localStorageService.getToken().length;
	}

	private notifyTimesLeft() {
		const refreshToken = this._localStorageService.getRefreshToken();
		const tokenExpirationDate = this._localStorageService.getTokenExpirationDate(refreshToken);

		const minutesLeft = Math.round((tokenExpirationDate.valueOf() - new Date().valueOf()) / this.ONE_MINUTE);

		if (minutesLeft < 21) {
			this._notificationService.notify(
				'warn',
				'Sitzung',
				'Ihre Sitzung endet in ca. ' + minutesLeft + ' Minuten.'
			);
		}
	}
}
