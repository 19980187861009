import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtAuthenticationRequest } from '../dto/JwtAuthenticationRequest';
import { LocalStorageService } from './local-storage.service';
import { Observable, of } from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	static token: string = null;
	userIsChangingPassword: boolean = false;

	constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

	signinUser(authenticationRequest: JwtAuthenticationRequest) {
		return this.http.post(`api/login`, authenticationRequest);
	}

	signinSsoUser(ssoToken: string) {
		return this.http.post(`api/loginSso`, ssoToken);
	}

	logout() {
		this.localStorageService.signOut();
	}

	isAuthenticated() {
		let token: string = this.localStorageService.getToken();
		let refreshToken = this.localStorageService.getRefreshToken();
		return token != null && !this.localStorageService.isTokenExpired(refreshToken);
	}

	isFullyAuthenticated() {
		let isAuthenticated = this.isAuthenticated();
		let isFirstLoginString = this.localStorageService.get('IS-FIRST-LOGIN');
		let isFirstLogin = JSON.parse(isFirstLoginString);

		return isAuthenticated && (!isFirstLogin && !this.userIsChangingPassword);
	}

	setUserIsChangingPassword() {
		this.userIsChangingPassword = true;
	}

	unsetUserIsChangingPassword() {
		this.userIsChangingPassword = false;
	}

	getToken(): Observable<string> {
		const token = this.localStorageService.getToken();
		const isTokenExpired = this.localStorageService.isTokenExpired(token);

		if (!isTokenExpired) {
			return of(token);
		}

		return this.refreshToken();
	}

	refreshToken(): Observable<string> {
		const url = 'api/refreshToken';

		// append refresh token if you have one
		const refreshToken = this.localStorageService.getRefreshToken();
		return this.http
			.get(url, {
				headers: new HttpHeaders().set('refreshToken', refreshToken ? refreshToken : ''),
				observe: 'response'
			})
			.pipe(
				share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
				map((res) => {
					const token = res.headers.get('token');
					const newRefreshToken = res.headers.get('refreshToken');

					// store the new tokens
					this.localStorageService.saveRefreshToken(newRefreshToken);
					this.localStorageService.saveToken(token);

					return token;
				}),
				catchError(() => {
					return of('');
				})
			);
	}
}
