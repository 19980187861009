import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'src/app/core/services/http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CaptchaResponse } from '../dto/CaptchaResponse';

@Injectable()
export class CaptchaService {
	readonly captchaUrl = 'api/captcha/';
	private handleError: HandleError;

	constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
		this.handleError = httpErrorHandler.createHandleError('CaptchaService');
	}

	getCaptcha(): Observable<CaptchaResponse> {
		return this.http
			.get<CaptchaResponse>(`${this.captchaUrl}get`)
			.pipe(catchError(this.handleError('getCaptcha', null)));
	}
}
