import { Component } from '@angular/core';
import { DocumentBase } from '../document.base';

@Component({
	selector: 'mpp-faq',
	templateUrl: './faq.component.html'
})
export class FaqComponent extends DocumentBase {
	TYPE = 'faq';
	title = 'FAQ';
}
