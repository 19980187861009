import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Router } from '@angular/router';
import { LogService } from '../../../../core/services/log/log.service';
import { LoginResponse } from '../../../../core/dto/LoginResponse';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
	selector: 'mpp-sso-success',
	templateUrl: './sso-success.component.html',
	styleUrls: [ './sso-success.component.scss' ]
})
export class SsoSuccessComponent implements OnInit {
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router,
		private logService: LogService,
		private authService: AuthService
	) {
		this.localStorageService = localStorageService;
		this.router = router;
		this.logService = logService;
		this.authService = authService;
	}

	ngOnInit() {
		this.logService.debug(decodeURIComponent(document.cookie));
		let token = this.extractSsoToken(document.cookie);
		this.authService.signinSsoUser(token).subscribe(
			(loginResponse: LoginResponse) => {
				this.localStorageService.saveToken(loginResponse.jwtToken);
				this.router.navigateByUrl('/home');
			},
			(error) => {
				this.logService.debug('Sso failed: ' + error);
				this.router.navigateByUrl('/login');
			}
		);
	}

	extractSsoToken(cookies: string): string {
		let token = '';
		let tokenArray = cookies.split(';');
		tokenArray.forEach((cookie) => {
			this.logService.debug(decodeURIComponent(cookie));
			let cookieArray = cookie.split('=');
			let name = cookieArray[0].trim();
			this.logService.debug(decodeURIComponent(name));
			if (name === 'casToken') {
				token = cookieArray[1];
			}
		});
		this.logService.debug(decodeURIComponent(token));
		return token;
	}
}
