import { Component } from '@angular/core';
import { DocumentBase } from '../document.base';

@Component({
	selector: 'mpp-impressum',
	templateUrl: './impressum.component.html'
})
export class ImpressumComponent extends DocumentBase {
	TYPE = 'impressum';
	title = 'Impressum';
}
