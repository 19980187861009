import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from 'src/app/core/services/header-title.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import * as fromBenutzerActions from '../../store/benutzer.actions';

@Component({
	selector: 'mpp-meine-daten',
	templateUrl: './meine-daten.component.html'
})
export class MeineDatenComponent implements OnInit {
	constructor(private headerTitleService: HeaderTitleService, private store: Store<AppState>) {
		this.store.dispatch(new fromBenutzerActions.GetBenutzer());
	}

	ngOnInit(): void {
		this.headerTitleService.setTitle('Meine Daten');
	}
}
