import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentRoutingModule } from './document-routing.module';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { HilfeComponent } from './pages/hilfe/hilfe.component';
import { FaqComponent } from './pages/faq/faq.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { HtmlTextService } from './services/html-text.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [ CommonModule, DocumentRoutingModule, SharedModule ],
	providers: [ HtmlTextService ],
	declarations: [ ImpressumComponent, HilfeComponent, FaqComponent, DatenschutzComponent, KontaktComponent ]
})
export class DocumentModule {}
