import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';


@Injectable({
	providedIn: 'root'
})
export class RoleService {
	userRoles: string[];
	initWithToken: boolean;

	constructor(private localStorageService: LocalStorageService) {
		this.userRoles = [];
		this.initWithToken = false;
		this.init();
	}

	init() {
		let tokenPayload = this.localStorageService.getTokenPayload();
		if (tokenPayload) {
			this.initWithToken = true;
			let rollenModel: any = tokenPayload.rollen;
			rollenModel.forEach((element) => {
				this.userRoles.push(element.funktion);
			});
		}
	}

	hasRole(role: string): boolean {
		if (!this.initWithToken) {
			this.init();
		}
		return this.userRoles.includes(role);
	}
}
