import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PasswordChangeService } from '../../../../core/services/password-change.service';
import { MppRoutes } from '../../../../core/common/MppRoutes';
import { NgForm } from '@angular/forms';
import { ChangePasswordRequest } from '../../../../core/dto/ChangePasswordRequest';
import { MppRoutingService } from '../../../../core/services/mpp-routing-service';
import { PasswordValidationResponse } from '../../../../core/dto/PasswordValidationResponse';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
	selector: 'mpp-change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
	@Output() onSuccess = new EventEmitter<void>();
	@Output() onFail = new EventEmitter<void>();
	@Output() onCancel = new EventEmitter<void>();

	passwordCriteriaMessages: string[];
	passwordChangeErrors: any;
	passwordIsChanged: boolean;
	passwordChangeIsPending: boolean = false;
	isAuthenticated: boolean = false;

	constructor(
		private _passwordChangeService: PasswordChangeService,
		private _localStorageService: LocalStorageService,
		private _routingService: MppRoutingService,
		private _authService: AuthService
	) {}

	ngOnInit() {
		if (this._localStorageService.isTokenExpired()) {
			this._routingService.goTo(MppRoutes.LOGIN);
			return;
		}
		this.getPasswordConstraintsAndCreatePasswordCriteriaMessages();
	}

	checkIsAuthenticated() {
		this.isAuthenticated = this._authService.isFullyAuthenticated();
		return this.isAuthenticated;
	}

	onPasswordChange(form: NgForm) {
		this.passwordChangeIsPending = true;
		const changeInitialPasswordRequest = new ChangePasswordRequest(
			form.value.currentPassword,
			form.value.newPassword,
			form.value.newPasswordRepeat
		);

		this._passwordChangeService
			.changePassword(changeInitialPasswordRequest)
			.subscribe(
				(passwordValidationResponse) => this.changePasswordResponseHandler(passwordValidationResponse),
				(error) => this.changePasswordResponseErrorHandler(error)
			);
	}

	onCancelHandler() {
		this.onCancel.emit();
	}

	private changePasswordResponseHandler(passwordValidationResponse: PasswordValidationResponse) {
		this.passwordChangeIsPending = false;
		this.passwordIsChanged = passwordValidationResponse.valid;

		if (!this.passwordIsChanged) {
			this.passwordChangeErrors = passwordValidationResponse.errors;
			this.onFail.emit();
		} else {
			this.onSuccess.emit();
		}
	}

	private changePasswordResponseErrorHandler(error: any) {
		this.passwordChangeIsPending = false;
		this.passwordChangeErrors = [ 'Ihr Token ist ungültig!' ];
		console.debug(error);
	}

	private getPasswordConstraintsAndCreatePasswordCriteriaMessages() {
		this.getPasswordConstraints().subscribe((passwordConstraints) => {
			this.passwordCriteriaMessages = this._passwordChangeService.createPasswordCriteriaMessages(
				passwordConstraints
			);
		});
	}

	private getPasswordConstraints() {
		return this._passwordChangeService.getPasswordConstraints();
	}
}
