import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GrowlModule } from 'primeng/growl';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthService } from './core/services/auth.service';
import { AuthGuard } from './core/services/auth-guard.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { Interceptor } from './core/services/interceptor.service';
import { APP_BASE_HREF } from '@angular/common';
import { SharedModule } from './modules/shared/shared.module';
import { NotificationsService } from './core/services/notifications.service';
import { NotificationsComponent } from './modules/shared/components/notifications/notifications.component';
import { LogService } from './core/services/log/log.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HttpErrorHandler } from './core/services/http-error-handler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InputSwitchModule } from 'primeng/inputswitch';
import { HomeComponent } from './core/components/home/home.component';
import { HomePageComponent } from './core/components/home/home-page.component';
import { BenutzerService } from './core/services/benutzer.service';
import { HeaderTitleService } from './core/services/header-title.service';
import { AppConfigService } from './core/services/app-config.service';

export function tokenGetter() {
	return sessionStorage.getItem('X-AUTH-TOKEN');
}

const appInitializerFn = (appConfig: AppConfigService) => {
	return () => {
		return appConfig.loadAppConfig();
	};
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		HttpClientModule,
		AppRoutingModule,
		SharedModule,
		InputSwitchModule,
		JwtModule.forRoot({
			config: {
				tokenGetter,
				whitelistedDomains: [ 'localhost:4200' ],
				blacklistedRoutes: [ 'localhost4200:/auth/' ],
				headerName: 'X-AUTH-TOKEN',
				authScheme: ''
			}
		}),
		GrowlModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ HttpClient ]
			}
		})
	],
	providers: [
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFn,
			multi: true,
			deps: [ AppConfigService ]
		},
		AuthService,
		AuthGuard,
		Interceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true
		},
		{
			provide: APP_BASE_HREF,
			useValue: '/mpp'
		},
		NotificationsService,
		LogService,
		HttpErrorHandler,
		BenutzerService,
		HeaderTitleService
	],
	declarations: [ AppComponent, PageNotFoundComponent, NotificationsComponent, HomePageComponent, HomeComponent ],
	bootstrap: [ AppComponent ]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/mpp/assets/i18n/');
}
