import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HilfeComponent } from './pages/hilfe/hilfe.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { AuthGuard } from '../../core/services/auth-guard.service';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';

const routes: Routes = [
	{ path: 'datenschutz', component: DatenschutzComponent },
	{ path: 'faq', component: FaqComponent },
	{ path: 'hilfe', component: HilfeComponent, canActivate: [ AuthGuard ] },
	{ path: 'impressum', component: ImpressumComponent },
	{ path: 'kontakt', component: KontaktComponent, canActivate: [ AuthGuard ] }
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ]
})
export class DocumentRoutingModule {}
