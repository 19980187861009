import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';


import { BenutzerService } from '../../../core/services/benutzer.service';
import * as BenutzerActions from './benutzer.actions';
import { GET_BENUTZER } from './benutzer.actions';
import { map, startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class BenutzerEffects {
	constructor(private actions$: Actions, private benutzerService: BenutzerService) {}

	@Effect()
	loadBenutzer$: Observable<Action> = this.actions$.pipe(
		ofType(GET_BENUTZER),
		startWith(GET_BENUTZER),
		switchMap((action) =>
			this.benutzerService
				.getCurrentBenutzer()
				.pipe(map((benutzer) => new BenutzerActions.GetBenutzerSuccess(benutzer)))
		)
	);
}
