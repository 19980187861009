import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Benutzer } from '../../../../core/dto/Benutzer';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { LogService } from '../../../../core/services/log/log.service';
import { MppEmailValidation } from '../../../../core/validators/MppEmailValidation';
import { NotificationsService } from '../../../../core/services/notifications.service';

@Component({
	selector: 'mpp-benutzerkonto',
	templateUrl: './benutzerkonto.component.html'
})
export class BenutzerkontoComponent {
	static readonly emailRegExp: RegExp = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

	benutzerForm: FormGroup;
	showChangeEmailGroup: boolean = false;
	showChangePassword: boolean = false;

	@Input() changeEmailErrors;

	benutzer: Benutzer;
	@Input()
	set _benutzer(_benutzer: Benutzer) {
		this.benutzer = _benutzer;
		if (_benutzer) {
			this.showChangeEmailGroup = false;
			this.showChangePassword = false;
		}
	}
	@Output() onSaveEmail = new EventEmitter<NgForm>();

	constructor(
		private _formBuilder: FormBuilder,
		private _logService: LogService,
		private _notificationService: NotificationsService
	) {
		this.benutzerForm = this._formBuilder.group(
			{
				emailChangeNewEmail: [
					'',
					[ Validators.required, Validators.pattern(BenutzerkontoComponent.emailRegExp) ]
				],
				emailChangeNewEmailRepeat: [
					'',
					[ Validators.required, Validators.pattern(BenutzerkontoComponent.emailRegExp) ]
				]
			},
			{
				validator: MppEmailValidation.MatchEmail
			}
		);
	}

	toggleChangeEmailGroup() {
		this.showChangeEmailGroup = !this.showChangeEmailGroup;
		if (!this.showChangeEmailGroup) {
			this.benutzerForm.controls['emailChangeNewEmail'].reset('');
			this.benutzerForm.controls['emailChangeNewEmail'].markAsUntouched();
			this.benutzerForm.controls['emailChangeNewEmailRepeat'].reset('');
			this.benutzerForm.controls['emailChangeNewEmailRepeat'].markAsUntouched();
		}
	}

	toggleChangePassword() {
		this.showChangePassword = !this.showChangePassword;
	}

	onChangePasswordSuccess() {
		this._notificationService.translateAndNotify('success', 'Passwort', 'password.change.success');
		this.showChangePassword = false;
	}

	onChangePasswordCancel() {
		this._logService.debug('BenutzerKontoComponent onChangePasswordCancel()');
		this.showChangePassword = false;
	}

	saveEmail(benutzerForm: NgForm) {
		this.onSaveEmail.emit(benutzerForm);
	}
}
