import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'src/app/core/services/http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { RegistrationRequest } from '../dto/RegistrationRequest';
import { RegistrationResponse } from '../dto/RegistrationResponse';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RegistrationService {
	readonly registrationUrl = 'api/registration/';
	private handleError: HandleError;

	constructor(private _http: HttpClient, httpErrorHandler: HttpErrorHandler) {
		this.handleError = httpErrorHandler.createHandleError('RegistrationService');
	}

	makeRegistration(registrationRequest: RegistrationRequest): Observable<RegistrationResponse> {
		return this._http
			.post<RegistrationResponse>(`${this.registrationUrl}request`, registrationRequest)
			.pipe(catchError(this.handleError('makeRegistration', null)));
	}
}
