import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeineDatenComponent } from './components/meine-daten/meine-daten.component';

const routes: Routes = [ { path: '', component: MeineDatenComponent } ];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ]
})
export class MeineDatenRoutingModule {}
