import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NotificationsService } from './notifications.service';
import { RoleService } from './role.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
		private notificationService: NotificationsService,
		private roleService: RoleService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let expectedRole: string = route.data.expectedRole;

		if (this.authService.isFullyAuthenticated() && (!expectedRole || this.roleService.hasRole(expectedRole))) {
			return true;
		} else if (this.authService.isFullyAuthenticated()) {
			this.notificationService.translateAndNotify('error' , 'Sicherheit', 'security.access.allowed.not');
			this.router.navigate([ '/home' ]);
			return false;
		}

		let extras = {
			queryParams: {
				returnUrl: state.url
			}
		};
		this.router.navigate([ '/login' ], extras);

		return false;
	}
}
