import { HttpClient } from '@angular/common/http';
import { ChangeEmailRequest } from '../dto/ChangeEmailRequest';
import { ChangeEmailResponse } from '../dto/ChangeEmailResponse';
import { Injectable } from '@angular/core';
import { Benutzer } from '../dto/Benutzer';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

@Injectable()
export class BenutzerService {
	readonly benutzerUrl = '/mpp/api/benutzer/';
	private handleError: HandleError;

	constructor(private _http: HttpClient, httpErrorHandler: HttpErrorHandler) {
		this.handleError = httpErrorHandler.createHandleError('BenutzerService');
	}

	saveEmail(changeEmailRequest: ChangeEmailRequest): Observable<ChangeEmailResponse> {
		return this._http.post<ChangeEmailResponse>(`${this.benutzerUrl}change-email`, changeEmailRequest);
	}

	getCurrentBenutzer(): Observable<Benutzer> {
		return this._http
			.get<Benutzer>(`${this.benutzerUrl}currentuser`)
			.pipe(catchError(this.handleError('getCurrentBenutzer', null)));
	}
}
