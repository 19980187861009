import { Component } from '@angular/core';
import { DocumentBase } from '../document.base';

@Component({
	selector: 'mpp-kontakt',
	templateUrl: './kontakt.component.html'
})
export class KontaktComponent extends DocumentBase {
	TYPE = 'kontakt';
	title = 'Kontakt';
}
