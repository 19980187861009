import { Injectable, OnInit } from '@angular/core';
import { HtmlTextDocument } from '../../../core/dto/HtmlTextDocument';
import { HtmlTextService } from '../services/html-text.service';
import { AuthService } from '../../../core/services/auth.service';
import { HeaderTitleService } from 'src/app/core/services/header-title.service';

@Injectable()
export abstract class DocumentBase implements OnInit {
	TYPE = 'base';
	title = 'Base';
	docs: HtmlTextDocument[];
	isAuthenticated: boolean = false;

	constructor(
		private _htmlTextService: HtmlTextService,
		private _authService: AuthService,
		private headerTitleService: HeaderTitleService
	) {}

	ngOnInit() {
		this.headerTitleService.setTitle(this.title);
		this.getDocs();
	}

	checkIsAuthenticated() {
		this.isAuthenticated = this._authService.isFullyAuthenticated();
		return this.isAuthenticated;
	}

	getDocs() {
		this._htmlTextService.getHtmlTexts(this.TYPE).subscribe((response: HtmlTextDocument[]) => {
			this.docs = response;
		});
	}
}
