import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexRoutingModule } from './index-routing.module';
import { IndexComponent } from './pages/login/index.component';
import { FormsModule } from '@angular/forms';
import { AuthModule } from '../auth/auth.module';
import { LoginComponent } from '../auth/components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from '../auth/components/forgot-password/forgot-password.component';
import { RegistrationComponent } from '../auth/components/registration/registration.component';
import { ButtonModule } from 'primeng/button';
import { SsoSuccessComponent } from './pages/sso-success/sso-success.component';

@NgModule({
	imports: [ FormsModule, ButtonModule, CommonModule, IndexRoutingModule, AuthModule, SharedModule ],
	declarations: [ IndexComponent, LoginComponent, ForgotPasswordComponent, RegistrationComponent, SsoSuccessComponent]
})
export class IndexModule {}
